<template>
  <div id="home">
    <!-- <about>
    </about> -->
    <articles>
    </articles>
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      Articles: () => import('@/components/home/Articles'),
      About: () => import('@/components/home/About'),
      Subscribe: () => import('@/components/home/Subscribe'),
    },
  }
</script>
